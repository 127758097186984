import React from 'react'
import Logo from '../assets/mabeyn_apart_logo.svg'

function Bottom() {
  return (
    <div id='bottom' className='bottom-section'>
      <div className='d-flex flex-column justify-content-center h-100 align-items-center my-auto'> 
        <img src={Logo} alt="mabeyn" className='bottom-logo' />
        <p className='mt-1 text-secondary'>Tüm hakları saklıdır.</p>
      </div>
    </div>
  )
}

export default Bottom