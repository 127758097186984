import React from "react";
import HeaderImg from "../assets/mabeyn.jpeg";
import { Button } from 'react-bootstrap'

function Header() {

  
  return (
    <div id="anasayfa">
     <div className="header-contents">
     <img
        src={HeaderImg}
        alt="header"
        className="img-fluid"
        loading="lazy"
        />
      <div className="header-content">
        <p className="header-title">
          <span className="header-shadow">MABEYN’DE KONFOR</span> <br />
          <span>VE FERAHLIK BİR ARADA</span> <br />
        </p>
      <Button className="header-contact rounded">
         <a href="#iletisim" className="text-decoration-none">Sizi arayalım!</a>
      </Button>
      </div>
     </div>
    </div>
  );
}

export default Header;
