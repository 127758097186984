import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import Turnstile from "react-turnstile";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function Contact() {
  const [open, setOpen] = useState(false);
  const [isBigScreen, setIsBigScreen] = useState(false);
  const handleClose = () => setOpen(false);
  const handlesShow = () => setOpen(true) & setPerCheck(true);

  const [firstToken, setFirstToken] = useState("");
  const [perCheck, setPerCheck] = useState(false);
  const [formData, setFormData] = useState({});

  const TEST_SITEKEY = "0x4AAAAAAAwiEhAuJTY2Oc5d";

  const {
    register,
    isSubmitting,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    if (firstToken && perCheck) {
      setFormData(data);
      showInfo();
      reset();
      setPerCheck(false);
    }
  };

  useEffect(() => {
  }, [formData]);

  const showInfo = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      iconColor: "#ad8b57",
      title: "Mesajınızı aldık! En kısa sürede size ulaşacağız.",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 3000px)");

    const handleScreenChange = (event) => {
      setIsBigScreen(event.matches);
    };
    handleScreenChange(mediaQuery);

    mediaQuery.addEventListener("change", handleScreenChange);

    return () => {
      mediaQuery.removeEventListener("change", handleScreenChange);
    };
  }, []);

  const handleCheckboxChange = (event) => {
    setPerCheck(event.target.checked);
  };

  return (
    <div id="iletisim" className="p-4 page">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={isBigScreen ? "row w-75 mx-auto" : "row"}>
          <div className="col-lg-6">
            <div className="map-col">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.741120805767!2d30.52113007715158!3d37.81953230957856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c5c9e9bb08920b%3A0x46f82f7eaf65c1db!2sMabeyn%20Apart!5e0!3m2!1str!2str!4v1727265835152!5m2!1str!2str"
                width="100%"
                height="500px"
                style={{ border: 0 }}
                loading="lazy"
                title="Google map"
                className="iframe"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <p className="contact-header">SİZİ Arayalım</p>
            <div className="row">
              <div className="col-lg-12">
                <Form.Label className="mb-0">Ad</Form.Label>
                <Form.Control
                  className="lh-std"
                  type="text"
                  placeholder="Adınızı giriniz."
                  {...register("firstName", { required: true, minLength: 3 })}
                  aria-invalid={errors.firstName ? "true" : "false"}
                />
                {errors.firstName?.type === "required" && (
                  <span role="alert" className="text-danger">
                    Lütfen adnızı giriniz
                  </span>
                )}
                {errors.firstName?.type === "minLength" && (
                  <span role="alert" className="text-danger">
                    Ad en az 3 karakter olmalı.
                  </span>
                )}
              </div>
              <div className="col-lg-12 mt-2">
                <Form.Label className="mb-0">Soyad</Form.Label>
                <Form.Control
                  className="lh-std"
                  type="text"
                  placeholder="Soyadınızı giriniz."
                  {...register("lastName", { required: true, minLength: 3 })}
                  aria-invalid={errors.lastName ? "true" : "false"}
                />
                {errors.lastName?.type === "required" && (
                  <span role="alert" className="text-danger">
                    Lütfen soyadnızı giriniz
                  </span>
                )}
                 {errors.lastName?.type === "minLength" && (
                  <span role="alert" className="text-danger">
                    Soyad en az 3 karakter olmalı.
                  </span>
                )}
              </div>

              <div className="col-lg-12 mt-2">
                <Form.Label className="mb-0">Telefon</Form.Label>
                <Form.Control
                  className="lh-std"
                  type="tel"
                  placeholder="Örn. 05** *** ** **"
                  {...register("phone", { required: true, minLength: 10, maxLength: 12 })}
                  aria-invalid={errors.phone ? "true" : "false"}
                />
                {errors.phone?.type === "required" && (
                  <span role="alert" className="text-danger">
                    Lütfen telefon numaranızı giriniz
                  </span>
                )}
                  {errors.phone?.type === "minLength" && (
                  <span role="alert" className="text-danger">
                    Telefon numaranızı kontrol ediniz.
                  </span>
                )}
                  {errors.phone?.type === "maxLength" && (
                  <span role="alert" className="text-danger">
                    Telefon numaranızı kontrol ediniz.
                  </span>
                )}
              </div>

              <div className="col-lg-12 mt-2">
                <Form.Label className="mb-0">Mail</Form.Label>
                <Form.Control
                  className="lh-std"
                  type="email"
                  placeholder="Mail adresinizi giriniz."
                  {...register("mail", { required: true })}
                  aria-invalid={errors.mail ? "true" : "false"}
                />
                {errors.mail?.type === "required" && (
                  <span role="alert" className="text-danger">
                    Lütfen mail adresinizi giriniz.
                  </span>
                )}
              </div>

              <div className="col-lg-12 mt-2">
                <Form.Label className="mb-0">Mesajınız</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="İletmek istediğiniz mesajı yazınız."
                  {...register("message", { required: false })}
                />
              </div>

              <div className="col-lg-12">
                <div className="d-flex flex-row my-2">
                  <Form.Check
                    {...register("permission", { required: true })}
                    aria-invalid={errors.permission ? "true" : "false"}
                    type="checkbox"
                    checked={perCheck}
                    onChange={handleCheckboxChange}
                    className="me-2"
                  />
                  <div className="d-flex flex-column">
                    <p className="mb-0 kvkk-per">
                      <span onClick={handlesShow} className="text-primary me-2">
                        KVKK sözleşmesini
                      </span>
                      okudum onaylıyorum.
                    </p>

                    {errors.permission?.type === "required" && (
                      <span role="alert" className="text-danger mb-3">
                        Lütfen KVKK Sözleşmesini onaylayın.
                      </span>
                    )}
                  </div>
                </div>
                <Turnstile
                  onVerify={setFirstToken}
                  sitekey={TEST_SITEKEY}
                  onLoad={(widgetId) => {
                    console.log(`Widget loaded: ${widgetId}`);
                  }}
                />
                <Button type="submit" className="contact-btn mt-2">
                  Gönder
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>KVKK SÖZLEŞMESİ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-scroll">
          <article>
            Madde 1- Amaç ve Kapsam (a) Bu standart sözleşmenin amacı, kişisel
            verilerin yurt dışına aktarılmasında 24/3/2016 tarihli ve 6698
            sayılı Kişisel Verilerin Korunması Kanunu (bundan sonra “Kanun”
            olarak anılacaktır) ile 10/7/2024 tarihli ve 32598 sayılı Resmî
            Gazete’de yayımlanarak yürürlüğe giren Kişisel Verilerin Yurt Dışına
            Aktarılmasına İlişkin Usul ve Esaslar Hakkında Yönetmelik (bundan
            sonra “Yönetmelik” olarak anılacaktır) hükümlerine riayet edilmesini
            sağlamaktır. (b) Kişisel verileri yurt dışına aktaran veri işleyen
            (bundan sonra “veri aktaran” olarak anılacaktır) ve veri aktarandan
            kişisel verileri alan yurt dışındaki veri sorumlusu (bundan sonra
            “veri alıcısı” olarak anılacaktır) bu standart sözleşmeyi (bundan
            sonra “Sözleşme” olarak anılacaktır) kabul etmişlerdir. (c) Bu
            Sözleşme, Ek I’de detaylarına yer verilen yurt dışına kişisel veri
            aktarımı ile ilgili olarak uygulanır. (d) Bu Sözleşmenin ekleri
            (bundan sonra “Ekler” olarak anılacaktır), bu Sözleşmenin ayrılmaz
            bir parçasını oluşturur. Madde 2- Sözleşmenin Etkisi ve Değişmezliği
            (a) Herhangi bir ekleme, çıkarma veya değişiklik yapılmaması
            kaydıyla bu Sözleşme, Kanunun 9 uncu maddesinin dördüncü fıkrası ve
            Yönetmelik uyarınca ilgili kişinin aktarımın yapılacağı ülkede de
            haklarını kullanma ve etkili kanun yollarına başvurma imkânının
            bulunması dahil olmak üzere kişisel verilerin yurt dışına
            aktarılmasında sağlanacak uygun güvenceleri öngörür. (b) Bu
            Sözleşme; Kanun, Yönetmelik ve ilgili diğer mevzuat uyarınca veri
            aktaranın tabi olduğu yükümlülüklere halel getirmez. Madde 3- Üçüncü
            Taraf Yararlanıcı Hakları (a) İlgili kişiler, aşağıdaki istisnalar
            dışında, üçüncü taraf yararlanıcı olarak bu Sözleşme hükümlerini
            veri aktarana ve/veya veri alıcısına karşı ileri sürebilir: i) Madde
            1, Madde 2, Madde 3 ve Madde 6. ii) Madde 7.1 (b) ve Madde 7.3 (b).
            iii) Madde 16. (b) (a) fıkrası, ilgili kişilerin Kanun kapsamındaki
            haklarına halel getirmez.
          </article>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mx-auto modal-close-btn" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Contact;
