import React, { useEffect, useState } from "react";
import Logo from "../assets/mabeyn_apart_logo.svg";
import Menu from "../assets/icons/menu.png";
import Fb from '../assets/icons/facebook.svg'
import Ig from '../assets/icons/instagram.svg'
import Map from '../assets/icons/map.svg'
import Phone from '../assets/icons/phone.svg'

function Nav() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [hash, setHash] = useState(window.location.hash);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
    useEffect(() => {
      const onHashChange = () => {
        setHash(window.location.hash);
      };
      window.addEventListener('hashchange', onHashChange);
      return () => window.removeEventListener('hashchange', onHashChange);
    }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    const offcanvasElement = document.getElementById("offcanvasRight");

    const handleOffcanvasClose = () => {
      setOpen(false);
    };

    offcanvasElement.addEventListener("hide.bs.offcanvas", handleOffcanvasClose);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      offcanvasElement.removeEventListener("hide.bs.offcanvas", handleOffcanvasClose);
    };
  }, []);

  return (
    <div id="nav" className={isScrolled && !open ? "nav-scrl" : "nav-nscrl"}>
      <div className="logo w-45 p-4">
        <a href="#anasayfa">
        <img src={Logo} alt="logo" className="logo-img pt-1" />
        </a>
      </div>

      <div className="menu w-45 p-2">
        <button
          className="menu-btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          onClick={handleOpen}
        >
          <img src={Menu} alt="menu-icon" className="menu-icon" />
        </button>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">
              <a
                href="tel:+90 (246) 717 23 32"
                className="text-decoration-none call-us"
              >
                <img
                  src={Phone}
                  alt="phone icon"
                  className="phone-icon me-1"
                />
                +90 (246) 717 23 32
              </a>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="offcanvas-body d-flex align-items-center">
            <ul className="list-unstyled w-100">
              <li
                className={hash === "#anasayfa" ? "of-items-selected" : "of-items"}
                data-bs-dismiss="offcanvas"
                onClick={handleClose}
              >
                <a className={hash === "#anasayfa" ? "text-decoration-none menu-text-selected" : "text-decoration-none menu-text"} href="#anasayfa">
                  Anasayfa
                </a>
              </li>
              <li
              className={hash === "#hakkimizda" ? "of-items-selected" : "of-items"}
                data-bs-dismiss="offcanvas"
                onClick={handleClose}
              >
                <a
                 className={hash === "#hakkimizda" ? "text-decoration-none menu-text-selected" : "text-decoration-none menu-text"}
                  href="#hakkimizda"
                >
                  Hakkımızda
                </a>
              </li>
              <li
                 className={hash === "#galeri" ? "of-items-selected" : "of-items"}
                data-bs-dismiss="offcanvas"
                onClick={handleClose}
              >
                <a className={hash === "#galeri" ? "text-decoration-none menu-text-selected" : "text-decoration-none menu-text"} href="#galeri" >Galeri</a>
              </li>
              <li
                className={hash === "#iletisim" ? "of-items-selected" : "of-items"}
                data-bs-dismiss="offcanvas"
                onClick={handleClose}
              >
                <a  className={hash === "#iletisim" ? "text-decoration-none menu-text-selected" : "text-decoration-none menu-text"} href="#iletisim">
                  İletişim
                </a>
              </li>
            </ul>
          </div>

          <div className="offcanvas-footer">
            <div className="d-flex flex-row justify-content-center px-3 mx-auto pb-3">
              <a
                href="https://www.facebook.com/MabeynResidenceApart/?locale=tr_TR"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Fb} alt="facebook link" className="sc-links" />
              </a>
              <a
                href="https://www.instagram.com/explore/locations/376276611/mabeyn-residence-apart/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Ig}
                  alt="instagram link"
                  className="sc-links mx-4"
                />
              </a>
              <a
                href="https://maps.app.goo.gl/mFQ2tsLnC3x4M1UV7"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Map} alt="map link" className="sc-links" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;