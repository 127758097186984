import React from "react";
import AboutImg from '../assets/header-img.jpg'
import AboutImg2 from '../assets/gallery/1.jpg'
import {Parallax} from 'react-scroll-parallax'

function About() {
  return (
    <div className="row mb-5" id="hakkimizda">
      <div className="col-lg-6 my-auto about-content">
      <Parallax speed={10}>
        <p className="about-header">Aradığınız her şey burada</p>
        <p className="about-text part-1">
          Mabeyn Residence, konumu ve sunduğu olanaklarla şehrin merkezinde
          konforlu bir yaşam arayanlar için ideal bir seçenektir. Üniversiteye
          yakın mesafesi ve otobüs duraklarına olan yakınlığı, ulaşım açısından
          büyük bir kolaylık sağlar. Her tercihe uygun 2+1, 1+1 ve 1+0 daire
          seçenekleri ile farklı yaşam ihtiyaçlarına hitap eder.
        </p>
        <p className="about-text">
          Site içinde yer alan spor salonu, sağlıklı yaşamı desteklerken,
          bahçedeki dinlenme alanı ise günün stresini atabileceğiniz huzurlu bir
          ortam sunar. Ayrıca, otopark ve 7/24 sıcak su gibi günlük yaşamınızı
          kolaylaştıracak olanaklar da mevcuttur. Mabeyn Residence, sakinlerine
          pratik ve keyifli bir yaşam sunmayı amaçlar.
        </p>
        </Parallax>
      </div>

      <div className="col-lg-6 img-container">
      <img src={AboutImg} alt="about_img" className="about_img" />
      <img src={AboutImg2} alt="about_img" className="about_img_2" />
      </div>
    </div>
  );
}

export default About;
