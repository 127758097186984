import React, { useState } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import img1 from "../assets/gallery/1.jpg";
import img2 from "../assets/gallery/2.jpg";
import img3 from "../assets/gallery/3.jpg";
import img4 from "../assets/gallery/4.jpg";
import img5 from "../assets/gallery/5.jpg";
import img6 from "../assets/gallery/6.jpg";
import img7 from "../assets/gallery/7.JPG";
import img8 from "../assets/gallery/8.JPG";
import img9 from "../assets/gallery/9.JPG";
import img10 from "../assets/gallery/11.JPG";
import img11 from "../assets/gallery/12.jpeg";
import img12 from "../assets/gallery/13.JPG";
import { Parallax } from "react-scroll-parallax";

function Gallery() {
  const [currentImage, setCurrentImage] = useState(img1);

  const images = [
    { original: img1 },
    { original: img2 },
    { original: img3 },
    { original: img4 },
    { original: img5 },
    { original: img6 },
    { original: img7 },
    { original: img8 },
    { original: img9 },
    { original: img10 },
    { original: img11 },
    { original: img12 },
  ];

  const handleSlide = (currentIndex) => {
    setCurrentImage(images[currentIndex].original);
  };

  return (
    <div id="galeri" className="gallery-wrapper bg-dark">
      <div
        className="blur-background"
        style={{
          backgroundImage: `url(${currentImage})`,
        }}
      ></div>

      <div className="gallery-content">
        <Parallax speed={10}>
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            lazyLoad={true}
            onSlide={handleSlide}
          />
        </Parallax>
      </div>
    </div>
  );
}

export default Gallery;