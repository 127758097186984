import Nav from './components/nav'
import Bottom from './components/bottom'
import Header from './pages/header'
import About from './pages/about'
import Gallery from './pages/gallery'
import Contact from './pages/contact'
import {ParallaxProvider} from 'react-scroll-parallax'


function App() {
  return (
    <div className='container-fluid px-0 app-container'>
      <ParallaxProvider>
      <Nav />
      <Header />
      <About />
      <Gallery />
      <Contact />
      </ParallaxProvider>
      <Bottom />
    </div>
  );
}

export default App;
